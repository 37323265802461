@import "config";

.advantages {
  &-facts {
    margin-bottom: 120px;

    &__title {
      margin-bottom: 10px;
      text-align: center;
    }

    &__text {
      font-size: 18px;
      font-family: $font_core_rhino;
      color: #5c626b;
      margin-bottom: 50px;
      text-align: center;
    }

    &-slider {
      position: relative;
      max-height: 1080px;
      padding-bottom: 70%;

      &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-position: 40% center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: opacity .4s ease-in-out;
        opacity: 0;
        z-index: 1;

        &[data-active='true'] {
          opacity: 1;
          z-index: 2;
        }
      }

      &-nav {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: $content_layout_padding;
        transform: translateY(-50%);

        &__item {
          width: 430px;
          position: relative;
          padding: 15px 0 0 125px;
          cursor: pointer;

          &[data-active='true'] {
            .advantages-facts-slider-nav__icon {
              img {
                filter: invert(0%);
                opacity: 1;
              }
            }
          }

          &[data-active='false'] {
            .advantages-facts-slider-nav__icon {
              background: #fff !important;
            }
          }

          & + .advantages-facts-slider-nav__item {
            margin-top: 60px;
          }
        }

        &__icon {
          position: absolute;
          top: 0;
          left: 0;
          transition: all .3s ease-in-out;

          img {
            transition: all .3s ease-in-out;
            filter: invert(100%);
            opacity: .2;
          }
        }

        &__number {
          font-size: 74px;
          font-family: $font_kent_4f_printed;
          line-height: 1;
          margin-bottom: 5px;
          color: #36393e;
        }

        &__text {
          font-size: 16px;
          line-height: 24px;
          color: #5b5d60;
          font-family: $font_core_rhino;
        }
      }
    }

    &-item {
      background: #eae9e4;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      height: 100%;

      &__icon {
        margin: 0 auto 20px auto;
      }

      &__number {
        font-size: 40px;
        font-family: $font_kent_4f_printed;
        line-height: 1;
        color: #36393e;
        text-align: center;
        margin-bottom: 5px;
      }

      &__text {
        padding: 0 20px;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: #5b5d60;
        font-family: $font_core_rhino;
        margin-bottom: 15px;
      }

      &__photo {
        width: 100%;
        display: block;
        margin-top: auto;
      }
    }
  }

  &-story {
    &__row {
      display: flex;
      align-items: flex-end;
    }

    &__album {
      position: relative;
      width: 49%;
      margin-right: 10%;
      flex-shrink: 0;
      height: 700px;
      order: 0;

      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &[data-active='true'] {
          opacity: 1;
          z-index: 2;
        }
      }
    }

    &__body {
      width: 100%;
      order: 1;
      padding-right: $content_layout_padding;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__info {
      max-height: 545px;
      margin-bottom: 30px;
      position: relative;

      &-item {
        display: none;

        &[data-active='true'] {
          display: block;
          animation: fadeIn .5s ease-in-out;
        }
      }
    }

    &__title {
      word-break: break-word;
      line-height: 80px;
      max-width: 560px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 18px;
      color: #36393e;
      font-family: $font_core_rhino;
      line-height: 30px;
      max-width: 470px;
    }

    &__arrows {
      font-size: 0;
      margin-bottom: 35px;

      .button-arrow {
        & + .button-arrow {
          margin-left: 15px;
        }
      }
    }
  }

  &-stories {
    padding: 150px 0 110px 0;
    position: relative;

    &__title {
      margin-bottom: 10px;
      text-align: center;
    }

    &__text {
      font-size: 18px;
      font-family: $font_core_rhino;
      color: #5c626b;
      margin-bottom: 50px;
      text-align: center;
    }

    &__popup-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2010;
      background: rgba(14, 18, 20, .6);
      pointer-events: none;
      opacity: 0;
      font-size: 0;
      transition: opacity .3s ease-in-out, z-index 0s .3s, top 0s .3s;
      cursor: default;

      html.show_popup & {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .glide {
    &_story {
      display: none;
    }

    &_stories {
      .glide__slide {
        max-height: 645px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;

        div {
          pointer-events: none;
          padding-bottom: 180%;
        }

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 0;
        }
      }

      .glide__bullets {
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .advantages {
    &-story {
      &__title {
        font-size: 55px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .advantages {
    &-facts {
      margin-bottom: 40px;

      &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      &-slider {
        display: none;
      }
    }

    &-story {
      height: 100%;

      &_slider {
        display: none;
      }

      &__row {
        flex-direction: column;
        align-items: stretch;
        height: 100%;
      }

      &__album {
        width: auto;
        margin: auto 0 0 0;
        height: auto;
        order: 1;

        img {
          display: block;
          width: 100%;
        }
      }

      &__body {
        width: auto;
        padding: 0;
        display: block;
        order: 0;
      }

      &__info {
        min-height: 0;
        max-height: none;
        margin: 0;
        padding: 0 $content_layout_padding_table;
      }

      &__title {
        line-height: 1;
        margin: 0 0 10px 0;
        max-width: none;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        max-width: none;
      }
    }

    &-stories {
      padding: 40px 0;

      &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      &__popup-bg {
        position: fixed;
      }
    }

    .glide {
      &_story {
        display: block;

        .glide__slides {
          align-items: stretch;
        }

        .glide__slide {
          height: auto;
        }

        .glide__bullets {
          margin-top: 10px;
        }

        .glide__arrow {
          bottom: 35vw;
          position: absolute;
          z-index: 10;

          &--left {
            left: 10px;
          }

          &--right {
            right: 10px;
          }
        }
      }

      &_stories {
        .glide__bullets {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .advantages {
    &-story {
      &__info {
        padding: 0 $content_layout_padding_phone;
      }

      &__title {
        font-size: 30px;
      }
    }
  }
}
