$img_dir: '../img/';
$fonts_dir: '../fonts/';

$font_core_rhino: 'Core Rhino', Arial, sans-serif;
$font_kent_4f_printed: 'Kent 4F Printed', Arial, sans-serif;
$font_arial: Arial, sans-serif;

$content_min_width: 320px;
$content_max_width: 1560px;
$content_layout_padding: 60px;
$content_layout_padding_table: 30px;
$content_layout_padding_phone: 20px;
$header_height: 146px;
$header_height_mobile: 80px;
