@import "config";

.footer {
  margin-top: auto;
  padding: 0 0 50px 0;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__col {
    flex-shrink: 0;
    margin-right: 140px;

    &:last-child {
      width: 100%;
      flex-shrink: 1;
      margin-right: 0;
      text-align: right;
    }
  }

  &__social {
    font-size: 0;

    a {
      display: inline-block;
      vertical-align: top;
      font-size: 22px;
      text-decoration: underline;
      color: #3c3f43;
      font-family: $font_core_rhino;
      font-weight: 700;
      transition: all .2s;
      margin-bottom: 10px;

      & + a {
        margin-left: 45px;
      }

      &:hover {
        color: #9c1614;
        text-decoration: underline;
      }
    }
  }

  &__copyright {
    color: #9d9ba3;
    font-size: 16px;
    font-family: $font_core_rhino;
  }

  &__dev {
    font-size: 16px;
    color: #9d9ba3;
    font-family: $font_core_rhino;

    img {
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      margin-top: -3px;
      margin-left: 3px;
    }
  }

  &-menu {
    margin-bottom: 90px;

    &__title {
      color: #3c3f43;
      font-family: $font_core_rhino;
      font-weight: 700;
      font-size: 26px;
      margin-bottom: 20px;
    }

    &__item {
      margin-top: 10px;
      font-size: 18px;
      color: #3c3f43;

      a {
        color: #3c3f43;
        text-decoration: none;
        transition: all .2s;

        &:hover {
          color: #9c1614;
          text-decoration: underline;
        }
      }
    }
  }

  &-slider {
    margin-bottom: 100px;
    overflow: hidden;
    padding-top: 80px;

    &__tag {
      position: relative;
      pointer-events: none;
      z-index: 3;
      margin: -5% -50px 0 -50px;

      img {
        display: block;
        width: 100%;
      }
    }

    .glide {
      z-index: 10;

      &__slide {
        max-height: 590px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        div {
          pointer-events: none;
          padding-bottom: 65%;
        }
      }

      &__arrow {
        top: 50%;
        margin-top: -45px;
        position: absolute;

        &--left {
          left: 60px;
        }

        &--right {
          right: 60px;
        }
      }

      &__bullets {
        display: none;
      }
    }
  }
}

@media (max-width: 1500px) {
  .footer {
    &__col {
      margin-right: 50px;
    }

    &__social {
      a {
        & + a {
          margin-left: 30px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .footer {
    &__col {
      margin-right: 100px;

      &:last-child {
        width: 150px;
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    &__row {
      flex-wrap: wrap;

      &:first-child {
        max-width: 960px;
      }
    }

    &__col {
      margin: 0;

      &:last-child {
        width: 100%;
        text-align: left;
        margin-bottom: 30px;
      }
    }

    &__social {
      a {
        & + a {
          margin-left: 45px;
        }
      }
    }

    &-menu {
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 991.98px) {
  .footer {
    &-slider {
      margin-bottom: 40px;
      padding-top: 40px;

      .glide {
        &__arrow {
          margin-top: -15px;
          top: 50%;

          &--left {
            left: 10px;
          }

          &--right {
            right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .footer {
    padding: 0 0 30px 0;

    &__social {
      a {
        font-size: 18px;

        & + a {
          margin-left: 30px;
        }
      }
    }

    &__copyright {
      width: 100%;
      margin-bottom: 25px;
    }

    &-menu {
      &__title {
        font-size: 22px;
        margin-bottom: 15px;
      }

      &__item {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 665.98px) {
  .footer {
    &__row {
      display: block;
      max-width: none;
    }

    &__col {
      margin: 0;

      &:last-child {
        width: auto;
      }
    }

    &__social {
      margin: 35px 0;

      a {
        font-size: 16px;

        & + a {
          margin-left: 18px;
        }
      }
    }

    &__copyright {
      line-height: 24px;
    }

    &-menu {
      margin-bottom: 25px;

      &__title {
        font-size: 20px;
        margin-bottom: 8px;
      }

      &__item {
        margin-top: 5px;
        font-size: 16px;
      }
    }

    &-slider {
      &__tag {
        display: none;
      }

      .glide {
        &__arrow {
          top: calc(50% - 15px);
        }

        &__bullets {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }
}
