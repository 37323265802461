@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInUp {
	from {
		transform: translateY(10px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	25% {
		transform: scale3d(1.3, 1.3, 1.3);
	}

	50% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes rotate_cw {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
