@import "config";

.main {
  &[data-complete] {
    .main-progress {
      position: static;
      bottom: auto;
      left: auto;
      right: auto;
      margin-top: -112px;
    }
  }

  &__number {
    font-family: $font_core_rhino;
    font-weight: 700;
    font-size: 200px;
    line-height: 100px;
    letter-spacing: -20px;
    position: relative;

    &[data-active] {
      .main__number-text {
        color: currentColor;
      }
    }

    &_1 {
      .main__number-point {
        top: 10px;
        left: -8vw;

        &:before {
          display: none;
        }
      }
    }

    &_2 {
      .main__number-point {
        top: 50px;
        left: -80px;
      }
    }

    &_3 {
      .main__number-point {
        top: 95px;
        left: -50px;
      }
    }

    &_4 {
      .main__number-point {
        top: 160px;
        left: 155px;
      }
    }

    &-point {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: currentColor;
      pointer-events: none;
    }

    &-text {
      transition: color .6s;
      color: #eee;
    }
  }

  &__title {
    a {
      color: currentColor;
      text-decoration: none;

      &:hover {
        color: currentColor;
        text-decoration: none;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 34px;
    color: #565656;
    font-family: $font_core_rhino;
  }

  &-line {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    &_1 {
      left: 100px;
      right: 0;
      z-index: 1;
    }

    &_2 {
      width: 31px;
      top: 0;
      height: 100%;
    }

    &_3 {
      width: 962px;
      top: 0;
      height: 1052px;
    }

    &_4 {
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &_5 {
      top: 0;
      width: 84px;
      z-index: 5;
    }

    &__wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      height: 0;
      transition: all .2s linear;

      &_bg {
        bottom: 0;
        top: auto;
        height: 100%;

        svg {
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  &-cover {
    height: 100vh;
    position: relative;
    margin-top: -$header_height;
    background-color: #fefbf9;
    overflow: hidden;

    & + .header {
      .header__logo {
        margin-top: 20px;
        width: 101px;
        height: 120px;
      }

      .header__menu-trigger {
        margin-top: 27px;

        &:hover {
          background: #fef6f2;
        }
      }

      .header__btn-profile {
        background: #fef6f2;
        margin-top: 44px;

        &:hover {
          background: #9c1614;
        }
      }

      .header__city-select {
        margin-top: 44px;
      }
    }

    &__wrap {
      display: flex;
      align-items: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding-top: $header_height + 10px;
      padding-bottom: 112px;
      z-index: 2;
    }

    &__tag {
      pointer-events: none;
      z-index: 1;
      position: relative;
      margin: -5% -25px 0 -90px;

      img {
        display: block;
        width: 100%;
      }
    }

    &__title {
      color: #36393e;
      font-size: 100px;
      line-height: 100px;
      margin-bottom: 10px;
      font-family: $font_kent_4f_printed;
      max-width: 740px;
    }

    &__subtitle {
      font-size: 30px;
      line-height: 48px;
      color: #393c41;
      font-family: $font_core_rhino;
      margin-bottom: 25px;
      max-width: 560px;
    }

    &__text {
      font-size: 18px;
      line-height: 30px;
      color: #5c626b;
      font-family: $font_core_rhino;
      max-width: 610px;
    }

    &__start {
      width: 116px;
      height: 116px;
      border-radius: 50%;
      margin-top: 35px;
      cursor: pointer;
      transition: all .3s;
      background-image: url($img_dir + 'btn_start.svg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__bg {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      max-height: 88vh;
      max-width: 55vw;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 3;
      pointer-events: none;
    }

    .content-layout {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &-about {
    overflow: hidden;

    &__wrap {
      padding: 160px 0 130px 0;
      position: relative;
      z-index: 10;
    }

    &__row {
      display: flex;
      align-items: flex-start;
    }

    &__body {
      width: 725px;
      margin-right: 150px;
    }

    &__number {
      width: 580px;

      .main__text {
        padding-left: 15px;
      }
    }

    &__subtitle {
      color: #36393e;
      font-size: 26px;
      line-height: 30px;
      font-family: $font_core_rhino;
      margin-bottom: 60px;
    }

    &__link {
      margin-top: 70px;
      width: 185px;
      margin-left: 15px;
    }

    &__img {
      display: block;
      width: 100%;
      position: relative;
      z-index: 10;
    }

    .main__number {
      margin-bottom: 83px;
    }
  }

  &-advantages {
    &__wrap {
      padding: 70px 0 80px 0;
      position: relative;
      overflow: hidden;

      &:before {
        display: none;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;
    }

    &__number {
      flex-shrink: 0;
      margin-right: 100px;
      padding-left: 80px;
    }

    &__body {
      width: 900px;
    }

    .main {
      &__number {
        line-height: 140px;
      }

      &__text {
        color: #767a82;
      }
    }
  }

  &-vacancy {
    padding: 150px 0 0 0;
    position: relative;
    overflow: hidden;

    &:before {
      display: none;
    }

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__body {
      width: 100%;
      order: 0;
    }

    &__number {
      margin-left: 100px;
      flex-shrink: 0;
      order: 1;
    }

    &__profile {
      text-align: center;
      padding: 100px 0;
    }

    .main {
      &__title {
        a[data-open_popup] {
          color: #9c1614;
          display: inline-block;
          position: relative;
          text-decoration: none;
          white-space: nowrap;

          &:before {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            height: 2px;
            background: currentColor;
            opacity: .5;
            pointer-events: none;
          }

          &:hover {
            color: #36393e;
            text-decoration: none;
          }
        }
      }

      &__text {
        margin-bottom: 60px;
      }

      &__number {
        margin-top: -50px;
      }
    }

    .vacancy-empty {
      margin-top: 35px;
    }
  }

  &-culture {
    position: relative;

    &:before {
      display: none;
    }

    &__wrap {
      background: #73b5c3;
      padding: 85px 0 45px 0;
      position: relative;
      overflow: hidden;
    }

    &__body {
      text-align: center;
      max-width: 1680px;
      margin: 0 auto;
      padding: 0 280px;
    }

    &__fact {
      font-size: 26px;
      line-height: 34px;
      color: #494b4f;
      font-weight: 700;
      font-family: $font_core_rhino;
    }

    &__img {
      display: block;
      width: 100%;
    }

    .main {
      &__title {
        color: #fff;
      }

      &__text {
        color: #fff;
        margin-bottom: 30px;
      }

      &__number {
        float: right;
        margin-left: 50px;

        &-text {
          color: rgba(255, 255, 255, .1);
        }
      }
    }

    .content-layout {
      position: relative;
      z-index: 2;
    }
  }

  &-form {
    padding: 85px 0 120px 0;
    background: #f0f6fb;
    transition: background .3s;
    position: relative;
    z-index: 20;

    &[data-active='true'] {
      background: #9c1614;
      color: #fff;

      .main-line,
      .main-form__form {
        display: none !important;
      }

      .main__number {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__body {
      text-align: center;
      max-width: 1680px;
      margin: 0 auto;
      padding: 0 280px;
      position: relative;
    }

    &__form {
      display: flex;
      padding: 60px 140px 0 140px;
      justify-content: space-between;
      align-items: flex-start;

      > * {
        margin: 0 10px;
      }

      .input-select {
        width: 100%;
      }
    }

    &__btn {
      flex-shrink: 0;
      padding-right: 60px;
      position: relative;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 12px;
        right: 18px;
        width: 30px;
        height: 30px;
        background: #ffc000;
        border-radius: 50%;
        pointer-events: none;
      }
    }

    .main__number {
      position: absolute;
      margin-left: 50px;
      bottom: 0;
      right: 0;
      line-height: 80px;

      &-text {
        color: #e5eef5;
      }
    }

    .main__text {
      color: #424449;
    }

    .form-completed {
      padding: 0 !important;
      background: none;

      &__title,
      &__text {
        color: #fff;
      }

      .content-layout {
        padding: 0 !important;
      }
    }
  }

  &-progress {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 112px;
    background: #55575b;
    pointer-events: none;
    overflow: hidden;
    z-index: 1995;
    animation: fadeInUp .5s .5s both;

    &__marker {
      height: 112px;
      pointer-events: none;
    }

    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 180px;
      transition: width .2s linear, background .6s;
      background: #ffc000;

      &_clone {
        background: transparent;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      white-space: nowrap;
      transform: translateX(525px);
    }

    &__percent {
      color: #fff;
      font-size: 65px;
      font-family: $font_kent_4f_printed;
      flex-shrink: 0;
      margin-right: 90px;
      margin-top: 15px;
    }

    &__text {
      font-size: 34px;
      font-family: $font_core_rhino;
      color: #fff;
      flex-shrink: 0;
    }
  }

  .glide {
    &_facts {
      .glide__bullets {
        display: none;
      }
    }

    &_vacancy {
      .glide__slides {
        overflow: visible;
        padding-top: 4px;
      }

      .glide__bullets {
        margin-top: 30px;
      }
    }
  }
}

@media (max-height: 900px) {
  .main {
    &[data-complete] {
      .main-progress {
        margin-top: -60px;
      }
    }

    &-cover {
      &__wrap {
        padding-bottom: 60px;
      }

      &__title {
        font-size: 80px;
        line-height: 80px;
        margin-bottom: 5px;
        max-width: 640px;
      }

      &__subtitle {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 15px;
        max-width: 520px;
      }

      &__text {
        font-size: 16px;
        line-height: 22px;
        max-width: 560px;
      }

      &__start {
        width: 90px;
        height: 90px;
        margin-top: 25px;
      }
    }

    &-progress {
      height: 60px;

      &__marker {
        height: 60px;
      }

      &__percent {
        font-size: 50px;
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 1680px) {
  .main {
    &__number {
      &_1 {
        .main__number-point {
          left: -140px;
        }
      }
    }
  }
}

@media (max-width: 1460px) {
  .main {
    &-cover {
      &__title {
        font-size: 80px;
        line-height: 80px;
        margin-bottom: 5px;
        max-width: 640px;
      }

      &__subtitle {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 15px;
        max-width: 520px;
      }

      &__text {
        font-size: 16px;
        line-height: 22px;
        max-width: 560px;
      }

      &__start {
        width: 90px;
        height: 90px;
        margin-top: 25px;
      }
    }

    &-form {
      &__body {
        padding-left: 0;
      }

      &__form {
        padding-left: 0;

        > * {
          margin: 0 5px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .main {
    &[data-complete] {
      .main-progress {
        margin-top: -60px;
      }
    }

    &__number {
      &_2 {
        .main__number-point {
          left: -70px;
        }
      }
    }

    &-cover {
      &__wrap {
        padding-bottom: 60px;
      }

      &__title {
        font-size: 55px;
        line-height: 60px;
        max-width: 480px;
      }

      &__subtitle {
        font-size: 22px;
        max-width: 420px;
      }

      &__text {
        max-width: 480px;
      }
    }

    &-advantages {
      &__number {
        padding-left: 70px;
        margin-right: 70px;
      }

      .main__title {
        font-size: 64px;
      }
    }

    &-culture {
      &__body {
        padding-left: 0;
      }
    }

    &-progress {
      height: 60px;

      &__marker {
        height: 60px;
      }

      &__percent {
        font-size: 50px;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .main {
    &[data-complete] {
      .main-progress {
        margin-top: -40px;
      }
    }

    &__title {
      margin-bottom: 10px;
    }

    &__number {
      font-size: 80px;
      line-height: 60px;
      letter-spacing: -8px;

      &_1,
      &_2,
      &_3,
      &_4 {
        .main__number-point {
          top: 24px;
          left: -20px;
        }
      }

      &_1 {
        .main__number-point {
          &:before {
            display: block;
            position: absolute;
            top: -215px;
            left: -30px;
            width: 100vw;
            height: 230px;
            opacity: .3;
            min-width: 700px;
            z-index: -1;
            content: '';
            right: 0;
            pointer-events: none;
            background-image: url($img_dir + 'main_line_1_mob.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      &-point {
        width: 14px;
        height: 14px;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      max-width: 660px;
    }

    &-line {
      display: none;
    }

    &-cover {
      margin-top: -$header_height_mobile;
      height: auto;

      & + .header {
        .header__logo {
          width: 50px;
          height: 50px;
          margin-top: 15px;
        }

        .header__menu-trigger {
          margin-top: 15px;
        }

        .header__btn-profile-mobile,
        .header__city-select-mobile {
          background: #fef6f2;
        }
      }

      &__wrap {
        padding-top: $header_height_mobile + 20px;
        display: block;
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        padding-bottom: 20px;
      }

      &__tag {
        position: absolute;
        margin: 0;
        top: -5%;
        left: -6%;
        right: -2%;
      }

      &__title {
        max-width: none;
        padding-right: 20%;
      }

      &__subtitle {
        max-width: none;
        padding-right: 20%;
      }

      &__text {
        line-height: 24px;
        max-width: none;
        padding-right: 20%;
      }

      &__bg {
        position: relative;
        right: auto;
        bottom: auto;
        height: 640px;
        width: auto;
        max-height: none;
        max-width: none;
      }

      &__start {
        &:hover {
          transform: scale(1);
        }
      }

      .content-layout {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-about {
      &__wrap {
        padding: 60px 0;
      }

      &__row {
        display: block;
      }

      &__body {
        width: auto;
        margin: 0;
      }

      &__number {
        width: auto;
        padding-left: 20px;

        .main__text {
          padding-left: 5px;
        }
      }

      &__subtitle {
        margin-bottom: 20px;
      }

      &__link {
        margin-top: 0;
        width: 130px;
        margin-left: 5px;
      }

      .main__number {
        margin-bottom: 20px;
      }

      .main__text {
        margin-bottom: 40px;
      }
    }

    &-advantages {
      &__wrap {
        padding: 60px 0;

        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 5px;
          height: 687px;
          width: 585px;
          pointer-events: none;
          opacity: .3;
          content: '';
          background-image: url($img_dir + 'main_line_2_mob.svg');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      &__row {
        display: block;
        padding-left: 20px;
      }

      &__number {
        margin: 0 0 30px 0;
        padding: 0;
      }

      &__body {
        width: auto;
      }

      .main {
        &__number {
          line-height: 60px;
        }

        &__title {
          font-size: 55px;
        }
      }
    }

    &-vacancy {
      padding: 60px 0 0 0;
      overflow: visible;

      &:before {
        content: '';
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        height: 125px;
        pointer-events: none;
        opacity: .3;
        display: block;
        background-image: url($img_dir + 'main_line_3_mob.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: -1;
      }

      &__row {
        display: block;
        padding-left: 20px;
      }

      &__body {
        width: auto;
      }

      &__number {
        margin: 0 0 30px 0;
      }

      &__profile {
        padding: 25px 0 60px 0;
      }

      .main {
        &__text {
          margin: 0;
          max-width: none;
        }

        &__number {
          margin: 0;
        }
      }

      .vacancy-item {
        margin-top: 20px;
      }
    }

    &-culture {
      &:before {
        content: '';
        position: absolute;
        top: -155px;
        left: 0;
        right: 0;
        height: 345px;
        pointer-events: none;
        opacity: .3;
        display: block;
        background-image: url($img_dir + 'main_line_4_mob.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      &__wrap {
        padding: 40px 0 20px 0;
        overflow: visible;
        position: static;
      }

      &__body {
        max-width: none;
        margin: 0;
        padding: 0;
      }

      &__fact {
        font-size: 20px;
      }

      .main {
        &__text {
          margin: 0 auto 10px auto;
        }

        &__number {
          float: none;
          margin: 0 auto 30px auto;
          width: 100px;
        }
      }
    }

    &-form {
      padding: 40px 0 20px 0;

      &[data-active='true'] {
        .main__number {
          top: auto;
          transform: none;
        }
      }

      &__body {
        max-width: none;
        margin: 0;
        padding: 0;
      }

      &__form {
        flex-wrap: wrap;
        padding: 40px 0 0 0;

        .input__wrap {
          width: 49%;
          margin: 0 0 20px 0;
        }
      }

      &__btn {
        padding: 0 40px 0 20px;
        margin: 0 auto 20px auto;

        &:before {
          content: '';
          position: absolute;
          left: 100%;
          margin-left: -20px;
          bottom: 20px;
          height: 187px;
          width: 20vw;
          pointer-events: none;
          opacity: .3;
          display: block;
          background-image: url($img_dir + 'main_line_5_mob.svg');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          z-index: -1;
        }

        &:after {
          top: 13px;
          right: 12px;
          width: 14px;
          height: 14px;
        }
      }

      .main__number {
        position: relative;
        margin: 0 0 30px 0;
        bottom: auto;
        right: auto;
        line-height: 60px;
        text-align: center;
      }

      .main__text {
        margin: 0 auto;
      }
    }

    &-progress {
      height: 40px;

      &__marker {
        height: 40px;
      }

      &__bar {
        width: 1%;

        &_clone {
          position: static;
        }
      }

      &__row {
        transform: none;
        left: 0;
        justify-content: center;
      }

      &__percent {
        font-size: 30px;
        margin-right: 20px;
        margin-top: 5px;
      }

      &__text {
        font-size: 16px;
      }
    }

    .glide {
      &_facts {
        .glide__bullets {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 825px) {
  .main {
    &-form {
      &__btn {
        &:before {
          width: 18.5vw;
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .main {
    &__title {
      font-size: 30px;
    }

    &__number {
      font-size: 66px;
      line-height: 50px;
      letter-spacing: -6px;

      &_1,
      &_2,
      &_3,
      &_4 {
        .main__number-point {
          top: 19px;
        }
      }

      &_1 {
        .main__number-point {
          &:before {
            top: -215px;
            left: -20px;
            width: 130vw;
            min-width: 0;
          }
        }
      }
    }

    &-cover {
      & + .header {
        .header__logo {
          width: 40px;
          height: 40px;
          margin-top: 20px;
        }

        .header__menu-trigger {
          margin-top: 20px;
        }
      }

      &__tag {
        top: -2%;
      }

      &__title {
        font-size: 40px;
        line-height: 45px;
        padding-right: 0;
      }

      &__subtitle {
        font-size: 20px;
        line-height: 24px;
        padding-right: 0;
      }

      &__text {
        padding-right: 0;
      }

      &__start {
        width: 60px;
        height: 60px;
        background-image: url($img_dir + 'btn_start_mob.svg');
      }

      &__bg {
        height: 320px;
      }
    }

    &-about {
      &__wrap {
        padding: 40px 0;
      }

      &__subtitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
      }

      .main__text {
        margin-bottom: 30px;
      }
    }

    &-advantages {
      &__wrap {
        padding: 40px 0;

        &:before {
          width: 545px;
        }
      }

      &__number {
        margin-bottom: 20px;
      }

      .main {
        &__number {
          line-height: 50px;
        }

        &__title {
          font-size: 30px;
        }
      }
    }

    &-vacancy {
      padding: 40px 0 0 0;

      &:before {
        height: 100px;
      }

      &__number {
        margin: 0 0 20px 0;
      }

      &__profile {
        padding-bottom: 40px;
      }

      .main {
        &__title {
          a[data-open_popup] {
            &:before {
              bottom: 0;
              height: 1px;
            }
          }
        }
      }
    }

    &-culture {
      &:before {
        top: -145px;
      }

      .main {
        &__number {
          margin-bottom: 20px;
          width: 80px;
        }
      }
    }

    &-form {
      &__form {
        padding-top: 30px;
        display: block;
        max-width: 400px;
        margin: 0 auto;

        .input__wrap {
          width: 100%;
          margin-bottom: 25px;
        }
      }

      &__btn {
        margin: 20px auto;
        display: flex;

        &:before {
          width: 40vw;
        }
      }

      .main__number {
        margin-bottom: 20px;
        line-height: 50px;
      }
    }

    &-progress {
      &__percent {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 600px) {
  .main {
    &-form {
      &__btn {
        &:before {
          width: 38vw;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .main {
    &-form {
      &__btn {
        &:before {
          width: 35vw;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .main {
    &-form {
      &__btn {
        &:before {
          width: 31vw;
        }
      }
    }
  }
}
