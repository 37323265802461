@import "config";

.header {
  height: $header_height;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  html.popup_menu & {
    position: fixed;

    .header__menu-trigger {
      background: transparent;
      border-color: transparent;

      div {
        background: transparent;

        &:before {
          transform: translateY(0) rotate(45deg);
          background: #9c1614;
        }

        &:after {
          transform: translateY(0) rotate(-45deg);
          background: #9c1614;
        }
      }

      &:hover {
        background: transparent;
      }
    }

    .header__btn-profile {
      background: transparent;
      border-color: #9c1614;
      color: #9c1614;

      &:hover {
        background: #9c1614;
      }
    }

    .header__city-select {
      &:before {
        border-bottom-color: #9c1614;
        border-right-color: #9c1614;
      }

      .input-select__text {
        color: #9c1614;
      }
    }
  }

  html.popup_profile & {
    position: fixed;

    .header__menu-trigger {
      &:hover {
        background: transparent;
      }
    }

    .header__btn-profile {
      background: transparent;
      border-color: #9c1614;
      color: #9c1614;

      &:hover {
        background: #9c1614;
      }
    }

    .header__city-select {
      &:before {
        border-bottom-color: #9c1614;
        border-right-color: #9c1614;
      }

      .input-select__text {
        color: #9c1614;
      }
    }
  }

  &__logo {
    float: left;
    width: 77px;
    height: 92px;
    margin-top: 25px;
    background: url($img_dir + 'logo_sk.svg') 0 0 no-repeat;
    background-size: 100% auto;
    position: relative;
    transition: all .3s;

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__menu-trigger {
    float: right;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background: #9c1614;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    margin-left: 50px;
    transition: all .3s;
    border: 2px solid #9c1614;

    &:hover {
      background: #fff;

      div {
        background: #9c1614;

        &:before,
        &:after {
          background: #9c1614;
        }
      }
    }

    div {
      position: absolute;
      width: 28px;
      height: 3px;
      background: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      pointer-events: none;
      transition: all .3s;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 10px;
        transition: all .3s;
      }

      &:before {
        transform: translateY(-11px);
      }

      &:after {
        transform: translateY(11px);
      }
    }
  }

  &__btn-profile {
    float: right;
    margin: 38px 0 0 50px;

    &-mobile {
      display: none;
    }
  }

  &__city-select {
    float: right;
    margin-top: 38px;
    width: 250px;
    font-size: 22px;
    font-weight: 700;

    &:hover {
      &:before {
        border-bottom-color: #393c41 !important;
        border-right-color: #393c41 !important;
      }

      .input-select__text {
        color: #393c41 !important;
      }
    }

    &[data-expand] {
      &:before {
        border-bottom-color: #9c1614 !important;
        border-right-color: #9c1614 !important;
      }

      .input-select__text {
        border-color: #d7dee5;
        background: #fff;
        color: #9c1614 !important;
      }
    }

    &-mobile {
      display: none;
    }

    .input-select__text {
      border-color: transparent;
      color: #9c1614;
      text-decoration: underline;
      transition: all .2s;
      background: transparent;
      padding-right: 40px;
      line-height: 54px;
    }

    .input-select__list-item {
      color: #393c41;
      padding: 10px 20px;

      &:hover {
        color: #9c1614;
      }
    }
  }

  &-cover {
    height: 830px;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 340px;
    margin-top: -$header_height;
    margin-bottom: 100px;

    & + .header {
      .header__logo {
        margin-top: 20px;
      }

      .header__menu-trigger {
        margin-top: 27px;
      }

      .header__btn-profile {
        border-color: #fff;
        color: #fff;
        margin-top: 44px;
      }

      .header__city-select {
        margin-top: 44px;

        &:before {
          border-bottom-color: #fff;
          border-right-color: #fff;
        }

        .input-select__text {
          color: #fff;
        }
      }
    }

    &_contacts,
    &_about {
      margin-bottom: 0;
    }

    &__nav {
      text-align: center;
      font-size: 22px;
      color: #c3c3c3;
      margin-bottom: 50px;

      a {
        color: #fff;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }

      span {
        white-space: nowrap;
      }
    }

    &__title {
      text-align: center;
      font-family: $font_kent_4f_printed;
      font-size: 90px;
      color: #fff;
      line-height: 100px;
      max-width: 1100px;
      margin: 0 auto 40px auto;

      a {
        color: #fff;
        display: inline-block;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        &:before {
          content: '';
          position: absolute;
          bottom: 12px;
          left: 0;
          right: 0;
          height: 1px;
          background: rgba(255, 255, 255, .5);
          pointer-events: none;
        }

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    &__text {
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      max-width: 600px;
      margin: 0 auto;
      color: #dfdfdf;
      font-family: $font_core_rhino;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    &-cover {
      &__nav {
        margin-bottom: 40px;
      }

      &__title {
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .header {
    height: $header_height_mobile;

    html.popup_menu & {
      .header__menu-trigger {
        background: transparent !important;
      }
    }

    html.popup_menu &,
    html.popup_profile & {
      .header__btn-profile-mobile,
      .header__city-select-mobile {
        border-color: #9c1614;
        background: transparent;

        svg {
          path {
            fill: #9c1614;
          }
        }
      }
    }

    &__logo {
      width: 50px;
      height: 50px;
      margin-top: 15px;
    }

    &__menu-trigger {
      width: 50px;
      height: 50px;
      margin-left: 15px;
      margin-top: 15px;

      &:hover {
        background: #9c1614 !important;

        div {
          background: #fff;

          &:before,
          &:after {
            background: #fff;
          }
        }
      }

      div {
        width: 20px;

        &:before {
          transform: translateY(-7px);
        }

        &:after {
          transform: translateY(7px);
        }
      }
    }

    &__btn-profile {
      display: none;

      &-mobile {
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid #9c1614;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        margin-left: 15px;

        svg {
          display: block;
          width: 25px;
          height: 25px;

          path {
            fill: #9c1614;
          }
        }
      }
    }

    &__city-select {
      display: none;

      &-mobile {
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid #9c1614;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

        svg {
          display: block;
          width: 23px;
          height: 29px;

          path {
            fill: #9c1614;
          }
        }
      }
    }

    &-cover {
      margin-top: -$header_height_mobile;
      height: 100vh;
      padding-top: $header_height_mobile;
      margin-bottom: 35px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 100px;

      &_contacts {
        margin-bottom: 0;
      }

      &_about {
        margin-bottom: 0;
      }

      & + .header {
        .header__logo,
        .header__menu-trigger {
          margin-top: 15px;
        }

        .header__btn-profile-mobile,
        .header__city-select-mobile {
          border-color: #fff;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      &__nav {
        margin-bottom: 20px;
      }

      &__title {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 20px;

        a {
          &:before {
            bottom: 6px;
          }
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .header {
    &__logo {
      width: 40px;
      height: 40px;
      margin-top: 20px;
    }

    &__menu-trigger {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      margin-top: 20px;
    }

    &__btn-profile {
      &-mobile {
        width: 40px;
        height: 40px;
        margin-top: 20px;
        margin-left: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__city-select {
      &-mobile {
        width: 40px;
        height: 40px;
        margin-top: 20px;

        svg {
          width: 18px;
          height: 24px;
        }
      }
    }

    &-cover {
      height: 570px;
      padding-top: 200px;

      &_contacts {
        background-position: 75% 0;
      }

      &_vacancy {
        background-position: 44% 0;
      }

      &_culture {
        background-position: 66% 0;
      }

      &_about {
        background-position: 58% 0;
      }

      &_advantages {
        background-position: 56% 0;
      }

      & + .header {
        .header__logo,
        .header__menu-trigger {
          margin-top: 20px;
        }
      }

      &__nav {
        font-size: 16px;
        margin-bottom: 15px;
      }

      &__title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
