@import "config";

.about {
  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 70px;
  }

  &__album {
    position: relative;
    width: 49%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10%;
    flex-shrink: 0;
    height: 745px;
    order: 0;

    .button-arrow {
      display: none;
    }

    img {
      display: none;
    }
  }

  &__body {
    width: 100%;
    order: 1;
    padding-right: $content_layout_padding;
  }

  &__info {
    min-height: 400px;
    margin-bottom: 30px;
  }

  &__title {
    word-break: break-word;
    line-height: 80px;
    max-width: 560px;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 26px;
    max-width: 560px;
    color: #393c41;
    font-family: $font_core_rhino;
    line-height: 1;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 18px;
    color: #565656;
    font-family: $font_core_rhino;
    line-height: 34px;
    max-width: 470px;
    margin-bottom: 30px;
  }

  &__arrows {
    font-size: 0;
    margin-bottom: 80px;
    min-height: 90px;

    .button-arrow {
      & + .button-arrow {
        margin-left: 15px;
      }
    }
  }

  &-item {
    &[data-active='true'],
    &:hover {
      .about-item__photo {
        box-shadow: 0 0 0 3px #9c1614 inset;
      }
    }

    &__photo {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .3s;
      max-height: 210px;
      cursor: pointer;
      overflow: hidden;

      div {
        pointer-events: none;
        padding-bottom: 60%;
      }
    }
  }

  &-worth {
    background: #fefbf9;
    padding: 140px 0 70px 0;
    margin-bottom: 40px;

    &__title {
      text-align: center;
    }

    &__text {
      text-align: center;
      font-size: 18px;
      line-height: 34px;
      color: #565656;
      font-family: $font_core_rhino;
      max-width: 670px;
      margin: 0 auto 80px auto;
    }

    &-item {
      text-align: center;
      padding: 0 10px;
      margin: 0 auto 70px auto;
      max-width: 370px;

      &__icon {
        margin: 0 auto 30px auto;
      }

      &__title {
        font-size: 34px;
        color: #36393e;
        line-height: 1;
        font-family: $font_kent_4f_printed;
        margin-bottom: 10px;
        word-break: break-word;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        color: #5b5d60;
        font-family: $font_core_rhino;
      }
    }
  }

  &-story {
    overflow: hidden;
    background: #66aaa9;
    position: relative;

    &__title {
      color: #fff;
      margin-bottom: 10px;
      text-align: center;
    }

    &__text {
      text-align: center;
      font-size: 26px;
      font-family: $font_core_rhino;
      line-height: 1;
      margin-bottom: 60px;
      color: #fff;
    }

    &__line {
      position: absolute;
      left: 50%;
      top: -92px;
      bottom: 0;
      transform: translateX(-50%);
      pointer-events: none;
      z-index: 1;
      overflow: visible;

      path {
        fill: none;
        stroke: #bcd9d9;
        stroke-width: 1px;
        stroke-dasharray: 20 8;
        fill-rule: evenodd;
      }
    }

    &-item {
      cursor: pointer;

      &:hover {
        .about-story-item__photo {
          opacity: 1;
        }

        .about-story-item__point {
          box-shadow:
            0 0 0 6px currentColor,
            0 0 10px 6px rgba(255, 255, 255, .3);
        }
      }

      &__photo {
        height: 195px;
        margin-bottom: 40px;
        box-shadow: 0 0 10px 3px rgba(255, 255, 255, .3);
        transition: opacity .3s ease-in-out;
        opacity: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &__point {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transition: all .3s ease-in-out;
        background: currentColor;
        margin-bottom: 25px;
      }

      &__title {
        font-size: 74px;
        color: #fff;
        font-family: $font_kent_4f_printed;
        line-height: 1;
        margin-bottom: 20px;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        font-family: $font_core_rhino;
      }
    }

    .content-layout {
      position: relative;
      padding-top: 165px;
      padding-bottom: 130px;
      z-index: 2;
    }
  }

  .glide {
    &_about {
      margin-bottom: 80px;
    }

    &_history {
      .glide__slides,
      .glide__track {
        overflow: visible;
      }

      .glide__arrows {
        display: none;
      }

      .glide__bullets {
        margin-top: 70px;
      }
    }

    &__bullets {
      margin-top: 60px;
    }
  }
}

@media (max-width: 1460px) {
  .about {
    &-worth {
      &-item {
        &__title {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .about {
    &__album {
      margin-right: 50px;
    }
  }
}

@media (max-width: 991.98px) {
  .about {
    &__row {
      display: block;
      margin-bottom: 20px;
    }

    &__album {
      position: relative;
      width: auto;
      background: none;
      margin: 40px 0 0 0;
      height: auto;

      .button-arrow {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -15px;

        &_prev {
          left: 10px;
        }

        &_next {
          right: 10px;
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &__body {
      width: auto;
      padding: 0;
    }

    &__info {
      min-height: 0;
      margin: 0;
      padding: 0 $content_layout_padding_table;
    }

    &__title {
      line-height: 1;
      margin: 0 0 10px 0;
      max-width: none;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
      max-width: none;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      max-width: none;
    }

    &__arrows {
      display: none;
    }

    &-item {
      &[data-active='true'],
      &:hover {
        .about-item__photo {
          box-shadow: 0 0 0 2px #9c1614 inset;
        }
      }
    }

    &-worth {
      padding: 70px 0 0 0;
      margin-bottom: 35px;
    }

    &-story {
      &__text {
        font-size: 16px;
        margin-bottom: 30px;
      }

      &__line {
        top: -339px;

        path {
          stroke-dasharray: 10 5;
        }
      }

      &-item {
        &:hover {
          .about-story-item__point {
            box-shadow: none;
          }
        }

        &__photo {
          margin-bottom: 20px;
          opacity: 1;
          height: 160px;
        }

        &__point {
          width: 14px;
          height: 14px;
          margin-bottom: 20px;
        }

        &__title {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }

      .content-layout {
        padding: 40px 0 35px 0;
      }
    }

    .glide {
      &_about {
        margin-bottom: 40px;
      }

      &_history {
        .glide__arrows {
          display: block;
        }

        .glide__arrow {
          position: absolute;
          border-radius: 50%;
          background: rgba(36, 40, 48, .7);
          cursor: pointer;
          transition: all .2s;
          z-index: 10;
          width: 30px;
          height: 30px;
          top: 10vw;

          &:before {
            content: '';
            position: absolute;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            transform: rotate(45deg);
            top: 10px;
            left: 12px;
            width: 10px;
            height: 10px;
          }

          &:hover {
            background: rgba(36, 40, 48, 1);
          }

          &--left {
            left: 10px;
          }

          &--right {
            right: 10px;
            transform: scaleX(-1);
          }
        }

        .glide__bullets {
          margin-top: 30px;
        }
      }

      &__bullets {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 665.98px) {
  .about {
    &__info {
      padding: 0 $content_layout_padding_phone;
    }

    &-worth {
      padding: 40px 0;

      &__title {
        font-size: 30px;
        margin-bottom: 10px;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }

      &-item {
        padding: 0;
        margin: 35px 0 0 0;

        &__icon {
          margin: 0 auto 20px auto;
        }

        &__title {
          font-size: 24px;
          margin-bottom: 5px;
        }
      }
    }

    &-story {
      &__line {
        top: -374px;
      }

      &-item {
        &__photo {
          height: 140px;
        }
      }
    }

    .glide {
      &_history {
        .glide__arrow {
          top: 13vw;
        }
      }
    }
  }
}
