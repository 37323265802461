@import "config";

html {
  &.popup_menu,
  &.popup_profile {
    overflow: hidden;
  }
}

body {
	min-width: $content_min_width;
  color: #000;
  font-family: $font_core_rhino;
  padding-top: $header_height;
}

h1, .h1 {
  font-size: 74px;
  font-weight: 400;
  color: #36393e;
  font-family: $font_kent_4f_printed;
  padding: 0;
  margin-bottom: 20px;
  line-height: 1;
}

h3, .h3,
h4, .h4 {
  font-size: 24px;
  color: #424549;
  font-family: $font_core_rhino;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0;
  line-height: 1;
}

a {
  transition: color .2s;
}

:focus {
  outline: none !important;
}

.svg-icon-def {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header_height});

  &-layout {
    max-width: $content_max_width + $content_layout_padding * 2;
    padding: 0 $content_layout_padding;
    margin: 0 auto;
    width: 100%;
  }
}

.button {
  font-size: 22px;
  padding: 0 30px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: visible;
  background: #9c1614;
  transition: all .3s ease-in-out;
  text-decoration: none;
  height: 55px;
  border-radius: 50px;
  text-align: center;
  font-family: $font_core_rhino;
  color: #fff;
  font-weight: 700;
  border: 2px solid transparent;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &_green {
    background: #66aaa9;

    &:hover {
      color: #fff;
    }
  }

  &_bordered {
    border-color: #9c1614;
    color: #9c1614;
    background: none;

    &:hover {
      background: #9c1614;
      color: #fff !important;
      border-color: #9c1614 !important;
    }
  }

  &_no-border {
    color: #9c1614;
    background: none;

    &:hover {
      color: #9c1614;
    }
  }

  &-arrow {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 50%;
    background: rgba(36, 40, 48, .7);
    cursor: pointer;
    transition: opacity .3s, background .3s;
    display: inline-block;
    vertical-align: top;

    &:before {
      content: '';
      position: absolute;
      top: 34px;
      left: 39px;
      width: 22px;
      height: 22px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(45deg);
    }

    &:hover {
      background: rgba(36, 40, 48, 1);
    }

    &_light {
      background: #efefef;

      &:before {
        border-color: #6c6c6c;
      }

      &:hover {
        background: #efefef;
      }
    }

    &_next {
      transform: scaleX(-1);
    }
  }
}

.input {
  &__wrap {
    position: relative;
    width: 360px;
  }

  &__error {
    bottom: 100%;
    position: absolute;
    margin-bottom: 3px;
    font-size: 14px;
    color: #9c1614;
    text-align: left;
    pointer-events: auto;
    font-family: $font_core_rhino;
    line-height: 1;

    & ~ .input-text {
      border-color: #9c1614;
    }

    & ~ .input-select {
      .input-select__text {
        border-color: #9c1614;
      }
    }

    & ~ .input-checkbox {
      .input-checkbox__checkbox {
        border-color: #9c1614;
      }
    }
  }

  &-text {
    width: 100%;
    height: 55px;
    border-radius: 4px;
    border: 2px solid #d7dee5;
    color: #000212;
    font-weight: 400;
    font-size: 18px;
    padding: 0 20px;
    min-height: 0;
    transition: border .2s;
    margin: 0;

    &::-webkit-input-placeholder {
      color: #000212;
    }

    &::-moz-placeholder {
      color: #000212;
    }

    &:-moz-placeholder {
      color: #000212;
    }

    &:-ms-input-placeholder {
      color: #000212;
    }
  }

  &-checkbox {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    text-align: left;
    min-height: 19px;

    &__checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      border-radius: 4px;
      border: 2px solid #d7dee5;
      cursor: pointer;
      transition: all .2s;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;

      svg {
        opacity: 0;
        transition: all .2s;
        display: block;
        width: 12px;
        height: 9px;

        path {
          fill: #fff;
        }
      }
    }

    &__text {
      color: #000212;
      font-weight: 400;
      font-size: 18px;
      padding-left: 30px;
      position: relative;
      text-align: left;
      font-family: $font_core_rhino;
      line-height: 20px;

      a {
        color: #000212;
        text-decoration: underline;

        &:hover {
          color: #000212;
          text-decoration: none;
        }
      }
    }

    input {
      position: absolute;
      bottom: 100%;
      right: 100%;
      opacity: 0;
      width: 0;
      height: 0;
      z-index: -1;

      &:checked ~ .input-checkbox__checkbox {
        border-color: #9c1614;
        background: #9c1614;

        svg {
          opacity: 1;
        }
      }
    }
  }

  &-select {
    position: relative;
    width: 360px;
    cursor: pointer;
    font-size: 18px;
    font-family: $font_core_rhino;

    &[data-expand] {
      &:before {
        transform: rotate(225deg);
        top: 26px;
      }

      .input-select__list {
        opacity: 1;
        pointer-events: auto;
        height: auto;
      }
    }

    &_disabled {
      pointer-events: none;

      &:before {
        display: none;
      }

      .input-select__text {
        text-decoration: none !important;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 21px;
      right: 26px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid #9c1614;
      border-right: 2px solid #9c1614;
      transform: rotate(45deg);
      pointer-events: none;
      transition: all .2s;
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
    }

    &__text {
      height: 55px;
      border-radius: 4px;
      border: 2px solid #d7dee5;
      color: #000212;
      padding: 0 60px 0 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background: #fff;
      line-height: 53px;
      pointer-events: none;
      transition: border .2s;
    }

    &__list {
      opacity: 0;
      transition: opacity .3s;
      pointer-events: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 2px solid #d7dee5;
      border-radius: 0 0 4px 4px;
      background: #fff;
      z-index: 10;
      margin-top: -3px;
      max-height: 387px;
      overflow: auto;
      height: 0;

      &-item {
        padding: 14px 20px;
        color: #000212;
        transition: all .2s;

        & + .input-select__list-item {
          border-top: 2px solid #d7dee5;
        }

        &:hover {
          color: #9c1614;
        }
      }
    }
  }
}

.form {
  &-invite {
    background: #f0f6fb;
    padding: 105px 0 85px 0;
    position: relative;
    z-index: 20;

    &__title {
      text-align: center;
      margin-bottom: 10px;
    }

    &__text {
      text-align: center;
      color: #5f5e62;
      font-size: 18px;
      font-family: $font_core_rhino;
      margin-bottom: 80px;
    }

    &__form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .input-select {
        width: 100%;
      }
    }

    .input__wrap {
      flex-shrink: 0;
      width: 26%;
      margin-bottom: 30px;
    }

    .button {
      flex-shrink: 0;
      margin-bottom: 30px;
      display: block;
    }

    .content-layout {
      max-width: 1400px + $content_layout_padding * 2;
      margin: 0 auto;
    }
  }

  &-completed {
    background: #f0f6fb;
    padding: 105px 0;
    position: relative;
    z-index: 20;
    text-align: center;

    &__title {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    &__text {
      font-size: 18px;
      color: #424449;
      line-height: 24px;
    }
  }
}

.navigation {
  background: #f0f6fb;
  padding: 20px 0;
  display: flex;
  align-items: center;
  min-height: 95px;
  margin-bottom: 70px;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;

    li {
      padding: 0;
      margin: 0;
      display: inline-block;
      vertical-align: top;
      font-size: 22px;
      font-family: $font_core_rhino;
      color: #c3c3c3;
      line-height: 20px;

      &:after {
        content: 'I';
        display: inline-block;
        vertical-align: top;
        margin: 0 10px;
        pointer-events: none;
        color: #36393e;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:nth-last-child(2) {
        &:after {
          color: #c3c3c3;
        }
      }

      a {
        color: #36393e;
        text-decoration: none;

        &:hover {
          color: #36393e;
          text-decoration: underline;
        }
      }
    }
  }
}

.label {
  display: inline-flex;
  vertical-align: middle;
  height: 22px;
  border-radius: 4px;
  background: #f1f5f8;
  padding: 0 10px;
  align-items: center;
  text-transform: uppercase;
  color: #728392;
  font-family: $font_core_rhino;
  font-size: 10px;
  line-height: 1;
}

.circle-icon {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  img,
  svg {
    display: block;
    fill: #fff;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.not-found {
  text-align: center;
  padding: 100px 0;

  &__title {
    font-family: $font_kent_4f_printed;
    font-size: 300px;
    line-height: 1;
    color: #9c1614;
  }

  &__link {
    font-size: 22px;
    color: #565656;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        color: #9c1614;
        text-decoration: none;
      }
    }
  }
}

.error-text {
  text-align: center;
  font-size: 22px;
  color: #9c1614;
  font-weight: 700;
  font-family: $font_core_rhino;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &_inline {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    padding: 100px 0;
  }

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    margin-left: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;

    &:before {
      content: '';
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 54px;
      height: 54px;
      transform: translate(-50%, -50%);
    }

    &:nth-child(1) {
      margin-left: 0;
      background-color: #fd8d8c;

      &:before {
        background-image: url($img_dir + 'icon_salver.svg');
      }
    }

    &:nth-child(2) {
      background-color: #d1d73b;
      animation-delay: .4s;

      &:before {
        background-image: url($img_dir + 'icon_cap.svg');
      }
    }

    &:nth-child(3) {
      background-color: #ffc000;
      animation-delay: .8s;

      &:before {
        background-image: url($img_dir + 'icon_glass.svg');
      }
    }

    &:nth-child(4) {
      background-color: #97d4d7;
      animation-delay: 1.2s;

      &:before {
        background-image: url($img_dir + 'icon_sushi.svg');
      }
    }
  }
}

.show-mobile {
  display: none;
}

.show-tablet {
  display: none;
}

.hide-mobile {
  display: block;
}

.glide {
  margin: 0;

  &_disabled {
    .glide__arrows,
    .glide__bullets {
      display: none !important;
    }
  }

  &__arrow {
    z-index: 10;
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 50%;
    background: rgba(36, 40, 48, .7);
    cursor: pointer;
    transition: opacity .3s, background .3s;
    display: inline-block;
    vertical-align: top;

    &:before {
      content: '';
      position: absolute;
      top: 34px;
      left: 39px;
      width: 22px;
      height: 22px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(45deg);
    }

    &:hover {
      background: rgba(36, 40, 48, 1);
    }

    &--right {
      transform: scaleX(-1);
    }
  }

  &__bullets {
    display: block;
    text-align: center;
    margin: 0;
    font-size: 0;
  }

  &__bullet {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 10px 10px 0 10px;
    background: #e0e1e1;
    transition: all .2s;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;

    &.glide__bullet_last ~ .glide__bullet {
      display: none !important;
    }

    &--active {
      background: #9c1614;
      cursor: default;
    }
  }

  &_facts {
    display: none;
  }
}

.ps {
  &__rail-y {
    opacity: 1 !important;
    background-color: #e3e8ed !important;
    width: 4px;
    right: 5px;

    &:hover {
      .ps__thumb-y {
        width: 4px;
        background: #9c1614;
      }
    }
  }

  &__thumb-y {
    width: 4px;
    right: 0;
    background: #9c1614;
  }
}

@media (min-width: 992px) {
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 666px) {
  .hide-tablet-desktop {
    display: none;
  }
}

@media (max-width: 1200px) {
  .form {
    &-invite {
      &__form {
        flex-wrap: wrap;
      }

      .input__wrap {
        width: 49%;
      }

      .button {
        margin: 0 auto 30px auto;
      }
    }
  }
}

@media (max-width: 991.98px) {
  body {
    padding-top: $header_height_mobile;
  }

  h1, .h1 {
    font-size: 55px;
    margin-bottom: 15px;
  }

  .content {
    min-height: calc(100vh - #{$header_height_mobile});

    html.popup_menu &,
    html.popup_profile & {
      position: fixed;
      top: 0;
      padding-top: $header_height_mobile;
      left: 0;
      right: 0;
      overflow: hidden;
    }

    &-layout {
      padding: 0 $content_layout_padding_table;
    }
  }

  .input {
    &-text {
      height: 40px;
      font-size: 16px;
      padding: 0 10px;
    }

    &-checkbox {
      &__text {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-select {
      font-size: 16px;

      &[data-expand] {
        &:before {
          top: 19px;
        }
      }

      &:before {
        top: 14px;
        right: 16px;
      }

      &__text {
        height: 40px;
        padding: 0 60px 0 10px;
        line-height: 38px;
      }

      &__list {
        max-height: 212px;

        &-item {
          padding: 8px 10px;
        }
      }
    }
  }

  .button {
    font-size: 18px;
    padding: 0 40px;
    height: 40px;

    &-arrow {
      width: 30px;
      height: 30px;

      &:before {
        top: 10px;
        left: 12px;
        width: 10px;
        height: 10px;
        border-width: 1px;
      }

      &:hover {
        background: rgba(36, 40, 48, 1);
      }

      &_light {
        background: #efefef;

        &:before {
          border-color: #6c6c6c;
        }

        &:hover {
          background: #efefef;
        }
      }

      &_next {
        transform: scaleX(-1);
      }
    }
  }

  .circle-icon {
    width: 50px;
    height: 50px;
    padding: 10px;
  }

  .glide {
    margin: 0;

    &__arrow {
      width: 30px;
      height: 30px;

      &:before {
        top: 10px;
        left: 12px;
        width: 10px;
        height: 10px;
        border-width: 1px;
      }
    }

    &_facts {
      display: block;

      .glide__slides {
        align-items: stretch;
      }

      .glide__slide {
        height: auto;
      }

      .glide__bullets {
        margin-top: 10px;
      }
    }
  }

  .navigation {
    padding: 15px 0;
    min-height: 40px;
    margin-bottom: 40px;

    &__list {
      li {
        font-size: 16px;
      }
    }
  }

  .form {
    &-invite {
      padding: 40px 0 10px 0;

      &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }

    &-completed {
      padding: 80px 0;

      &__text {
        font-size: 16px;
      }
    }
  }

  .show-tablet {
    display: block;
  }

  .hide-tablet {
    display: none;
  }
}

@media (max-width: 665.98px) {
  h1, .h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }

  h3, .h3,
  h4, .h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .content {
    &-layout {
      padding: 0 $content_layout_padding_phone;
    }
  }

  .form {
    &-invite {
      &__form {
        display: block;
        min-width: 0;

        &__text {
          margin-bottom: 25px;
        }

        .input__wrap {
          width: auto;
        }
      }
    }

    &-completed {
      padding: 40px 0;

      &__title {
        font-size: 30px;
      }
    }
  }

  .not-found {
    padding: 30px 0 0 0;

    &__title {
      font-size: 140px;
    }

    &__link {
      font-size: 16px;
    }
  }

  .loading {
    &_inline {
      padding: 50px 0;
    }

    div {
      width: 50px;
      height: 50px;
      margin-left: 20px;

      &:before {
        width: 30px;
        height: 30px;
      }
    }
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
}
