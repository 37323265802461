@import "config";

.vacancy {
  margin-bottom: 130px;

  &__title {
    margin-bottom: 30px;

    a {
      color: #9c1614;
      display: inline-block;
      position: relative;
      text-decoration: none;
      white-space: nowrap;

      &:before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        height: 2px;
        background: currentColor;
        opacity: .5;
        pointer-events: none;
      }

      &:hover {
        color: #36393e;
        text-decoration: none;
      }
    }
  }

  &__text {
    font-size: 18px;
    color: #565656;
    font-family: $font_core_rhino;
    margin-bottom: 40px;
    line-height: 1;
  }

  &__form {
    display: flex;
    align-items: center;
    margin-bottom: 100px;

    &-break {
      display: none;
    }

    .input__wrap {
      margin: 0 40px 0 35px;
    }
  }

  &-detail {
    &__row {
      display: flex;
      align-items: flex-start;
    }

    &__body {
      width: 100%;
    }

    &__subtitle {
      font-size: 24px;
      color: #424549;
      font-family: $font_core_rhino;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1;
      margin-top: 45px;

      &_similar {
        font-size: 26px;
        color: #3c3f43;
        margin-bottom: 60px;
        margin-top: 100px;
      }
    }

    &__address {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 12px;
      color: #728392;
      font-family: $font_core_rhino;
    }

    &__cost {
      font-size: 28px;
      font-family: $font_core_rhino;
      font-weight: 700;
      color: #424549;
      line-height: 1;
      margin: 30px 0;
    }

    &__text {
      font-size: 18px;
      line-height: 34px;
      color: #565656;
      font-family: $font_core_rhino;
      padding-top: 45px;

      p {
        margin: 0;
        padding: 0;
      }
    }

    .vacancy-item {
      flex-shrink: 0;
      width: 355px;
      margin-left: 120px;
    }
  }

  &-list {
    &__more {
      text-align: center;
      margin-top: 45px;
    }

    &-item {
      margin-bottom: 65px;

      &__icon {
        width: 50px;
        height: 50px;
        margin: 0 0 20px 0;
        padding: 10px;
      }

      &__address {
        font-size: 12px;
        font-family: $font_core_rhino;
        color: #728392;
        line-height: 1;
        margin-bottom: 15px;
      }

      &__title {
        font-size: 24px;
        color: #22242f;
        font-family: $font_core_rhino;
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 1;

        a {
          color: #22242f;
          text-decoration: none;

          &:hover {
            color: #9c1614;
            text-decoration: none;
          }
        }
      }

      &__text {
        font-size: 16px;
        line-height: 26px;
        color: #565656;
        font-family: $font_core_rhino;

        p {
          margin: 0;
          padding: 0;
        }

        h3,
        h4 {
          font-size: 16px;
          margin-bottom: 5px;
          font-weight: 400;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            &:before {
              content: '— ';
            }
          }
        }
      }

      .label {
        margin-bottom: 15px;
      }
    }
  }

  &-item {
    position: relative;

    &[data-active='true'] {
      .vacancy-item-photo {
        &:before {
          box-shadow: 0 0 0 4px currentColor;
        }
      }

      .vacancy-item__link {
        cursor: default;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 1;
      font-family: $font_core_rhino;
      font-weight: 700;
      padding: 15px 0;
      color: #22242f;
    }

    &__text {
      font-size: 16px;
      color: #565656;
      line-height: 26px;
      font-family: $font_core_rhino;
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      &:hover ~ .vacancy-item-photo {
        &:before {
          box-shadow: 0 0 0 4px currentColor;
        }
      }
    }

    &-photo {
      position: relative;
      margin-bottom: 10px;
      padding-top: 100%;

      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        pointer-events: none;
      }

      &:before {
        z-index: 1;
        transition: all .3s;
      }

      &:after {
        z-index: 2;
        background: #f0f0ed;
      }

      &__img {
        display: block;
        width: 100%;
        position: relative;
        z-index: 3;
        margin-top: -100%;
      }

      &__icon {
        position: absolute;
        z-index: 4;
        width: 50px;
        height: 50px;
        padding: 10px;
        bottom: 10%;
        left: 3%;
        pointer-events: none;
        background: currentColor;

        img {
          width: auto;
        }
      }
    }
  }

  &-empty {
    background: #f0f6fb;
    padding: 112px 0;
    text-align: center;

    &__title {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    &__text {
      font-size: 18px;
      color: #424449;
      line-height: 24px;
      margin-bottom: 60px;
    }
  }

  .glide {
    margin-bottom: 130px;

    &__slides {
      overflow: visible;
      padding-top: 4px;
    }

    &__bullets {
      margin-top: 90px;
    }
  }
}

@media (max-width: 1200px) {
  .vacancy {
    &-detail {
      .vacancy-item {
        width: 250px;
        margin-left: 100px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .vacancy {
    margin-bottom: 80px;

    &__form {
      flex-wrap: wrap;

      &-break {
        display: block;
        width: 100%;
        margin-bottom: 15px;
      }

      .input__wrap {
        margin: 0 0 0 20px;
      }
    }

    &-detail {
      &__row {
        display: block;
      }

      &__body {
        width: auto;
      }

      &__subtitle {
        font-size: 20px;
        margin-bottom: 15px;
        margin-top: 35px;

        &_similar {
          font-size: 24px;
          margin-bottom: 20px;
          margin-top: 45px;
        }
      }

      &__cost {
        font-size: 20px;
        margin: 20px 0;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        padding-top: 35px;
      }

      .vacancy-item {
        width: auto;
        max-width: 355px;
        margin: 30px 0 0 0;
      }
    }

    &-list {
      &__more {
        margin-top: 10px;
      }
    }

    &-empty {
      padding: 80px 0;

      &__text {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 740px) {
  .vacancy {
    &__form {
      .input__wrap {
        width: 285px;
      }

      .input-select {
        width: auto;
      }
    }
  }
}

@media (max-width: 665.98px) {
  .vacancy {
    &__title {
      font-size: 30px;
      margin-bottom: 20px;

      a {
        &:before {
          bottom: 0;
          height: 1px;
        }
      }
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    &__form {
      margin-bottom: 35px;
      display: block;

      &-break {
        display: none;
      }

      &-btn {
        width: 100%;
      }

      .input__wrap {
        width: 100%;
        margin: 20px 0;
      }

      .input-select {
        width: auto;
      }

      button.button {
        margin: 0 auto;
        display: flex;
      }
    }

    &-list {
      &__more {
        margin-bottom: 40px;
      }

      &-item {
        margin-bottom: 35px;

        &__icon {
          width: 40px;
          height: 40px;
        }

        &__title {
          font-size: 20px;
        }

        &__text {
          line-height: 24px;
        }

        .label {
          margin-bottom: 10px;
        }
      }
    }

    &-item {
      &__title {
        font-size: 20px;
      }

      &__text {
        line-height: 24px;
      }

      &-photo {
        &__icon {
          width: 40px;
          height: 40px;
          bottom: 10%;
          left: 5%;
        }
      }
    }

    &-empty {
      padding: 40px 0;

      &__title {
        font-size: 30px;
        margin-bottom: 15px;
      }

      &__text {
        margin-bottom: 30px;
        font-size: 16px;
      }
    }

    .glide {
      margin-bottom: 40px;

      &__bullets {
        margin-top: 15px;
      }
    }
  }
}
