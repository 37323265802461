@import "config";

.contacts {
  &-info {
    padding: 140px 0 70px 0;

    &-item {
      text-align: center;
      margin-bottom: 60px;

      &__icon {
        margin: 0 auto 35px auto;
      }

      &__title {
        font-size: 34px;
        color: #36393e;
        font-family: $font_kent_4f_printed;
        margin-bottom: 10px;
        line-height: 1;
      }

      &__text {
        font-size: 22px;
        color: #5b5d60;
        font-family: $font_core_rhino;

        a {
          color: #5b5d60;
          text-decoration: none;

          &:hover {
            color: #5b5d60;
            text-decoration: underline;
          }
        }
      }
    }

    .content-layout {
      max-width: 1400px + $content_layout_padding * 2;
      margin: 0 auto;
    }
  }
}

@media (max-width: 991.98px) {
  .contacts {
    &-info {
      &-item {
        &__title {
          font-size: 26px;
        }

        &__text {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .contacts {
    &-info {
      padding: 40px 0 0 0;

      &-item {
        margin-bottom: 40px;

        &__icon {
          margin-bottom: 20px;
        }

        &__title {
          font-size: 24px;
          margin-bottom: 6px;
        }

        &__text {
          font-size: 16px;
        }
      }
    }
  }
}
