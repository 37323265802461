@charset "UTF-8";
@import '~bootstrap/scss/bootstrap.scss';
@import '~@glidejs/glide/src/assets/sass/glide.core.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import 'fonts';
@import 'animation';
@import 'base';
@import 'header';
@import 'footer';
@import "popup";
@import "main";
@import "contacts";
@import "vacancy";
@import "culture";
@import "about";
@import "advantages";
