@import "config";

@font-face {
  font-family: 'Core Rhino';
  src: url($fonts_dir + 'CoreRhino45Regular.eot');
  src: url($fonts_dir + 'CoreRhino45Regular.eot?#iefix') format('embedded-opentype'),
  url($fonts_dir + 'CoreRhino45Regular.woff2') format('woff2'),
  url($fonts_dir + 'CoreRhino45Regular.woff') format('woff'),
  url($fonts_dir + 'CoreRhino45Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino';
  src: url($fonts_dir + 'CoreRhino65Bold.eot');
  src: url($fonts_dir + 'CoreRhino65Bold.eot?#iefix') format('embedded-opentype'),
  url($fonts_dir + 'CoreRhino65Bold.woff2') format('woff2'),
  url($fonts_dir + 'CoreRhino65Bold.woff') format('woff'),
  url($fonts_dir + 'CoreRhino65Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Kent 4F Printed';
  src: url($fonts_dir + 'Kent4FPrinted.eot');
  src: url($fonts_dir + 'Kent4FPrinted.eot?#iefix') format('embedded-opentype'),
  url($fonts_dir + 'Kent4FPrinted.woff2') format('woff2'),
  url($fonts_dir + 'Kent4FPrinted.woff') format('woff'),
  url($fonts_dir + 'Kent4FPrinted.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
