@import "config";

.culture {
  margin-top: -30px;

  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 70px;
  }

  &__album {
    position: relative;
    width: 49%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10%;
    flex-shrink: 0;
    height: 745px;
    order: 0;

    .button {
      position: absolute;
      right: 50px;
      bottom: 50px;
      transition: none;
    }

    .button-arrow {
      display: none;
    }

    img {
      display: none;
    }
  }

  &__body {
    width: 100%;
    order: 1;
    padding-right: $content_layout_padding;

    .label {
      font-size: 18px;
      color: #fff;
      height: 34px;
    }
  }

  &__info {
    min-height: 400px;
    margin-bottom: 30px;
  }

  &__title {
    word-break: break-word;
    line-height: 80px;
    max-width: 560px;
    margin: 30px 0 10px 0;
  }

  &__tag {
    font-size: 26px;
    color: #393c41;
    font-family: $font_core_rhino;
    line-height: 1;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 18px;
    color: #565656;
    font-family: $font_core_rhino;
    line-height: 34px;
    max-width: 470px;
  }

  &__arrows {
    font-size: 0;
    margin-bottom: 80px;
    min-height: 90px;

    .button-arrow {
      & + .button-arrow {
        margin-left: 15px;
      }
    }
  }

  &-item {
    &[data-active='true'],
    &:hover {
      .culture-item__photo {
        box-shadow: 0 0 0 3px #9c1614 inset;
      }
    }

    &__photo {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      transition: all .3s;
      max-height: 210px;
      cursor: pointer;
      overflow: hidden;

      &-handle {
        pointer-events: none;
        padding-bottom: 67%;
      }

      .label {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      color: #000212;
      font-family: $font_core_rhino;
      line-height: 1;
      margin-top: 20px;
      cursor: pointer;
      transition: color .2s;

      &:hover {
        color: #9c1614;
      }
    }
  }

  .glide {
    &_culture {
      margin-bottom: 80px;

      .glide__bullets {
        margin-top: 60px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .culture {
    &__album {
      margin-right: 50px;
    }
  }
}

@media (max-width: 991.98px) {
  .culture {
    margin-top: 0;

    &__row {
      display: block;
      margin-bottom: 20px;
    }

    &__album {
      position: relative;
      width: auto;
      background: none;
      margin: 100px 0 0 0;
      height: auto;

      .button {
        position: absolute;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        margin-bottom: 40px;
      }

      .button-arrow {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -15px;

        &_prev {
          left: 10px;
        }

        &_next {
          right: 10px;
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &__body {
      width: auto;
      padding: 0;

      .label {
        font-size: 12px;
        height: 22px;
      }
    }

    &__info {
      min-height: 0;
      margin: 0;
      padding: 0 $content_layout_padding_table;
    }

    &__title {
      font-size: 40px;
      line-height: 1;
      margin: 20px 0 10px 0;
      max-width: none;
    }

    &__tag {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      max-width: none;
    }

    &__arrows {
      display: none;
    }

    &-item {
      &[data-active='true'],
      &:hover {
        .culture-item__photo {
          box-shadow: 0 0 0 2px #9c1614 inset;
        }
      }

      &__photo {
        .label {
          display: none;
        }
      }

      &__title {
        display: none;
      }
    }

    .glide {
      &_culture {
        margin-bottom: 40px;

        .glide__bullets {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .culture {
    &__info {
      padding: 0 $content_layout_padding_phone;
    }
  }
}
