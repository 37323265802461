@import "config";

.popup {
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: all .3s ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 100%;
  max-width: 620px;
  padding-top: 90px;
  z-index: 2015;
  overflow: visible;

  &__wrap {
    padding: 0 70px 70px 70px;
    position: relative;
    overflow: auto;
    max-height: 85vh;
  }

  &__close {
    position: absolute;
    width: 47px;
    height: 47px;
    top: 30px;
    right: 30px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e9e9ea;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 22px;
      height: 2px;
      background: #bfc3cd;
      top: 23px;
      left: 12px;
      pointer-events: none;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    text-align: center;
    font-size: 34px;
    font-family: $font_kent_4f_printed;
    color: #36393e;
    margin-bottom: 5px;
    line-height: 1;
  }

  &__text {
    text-align: center;
    font-size: 18px;
    color: #88909b;
    line-height: 30px;
    margin-bottom: 30px;
    font-family: $font_core_rhino;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2010;
    font-size: 0;
    background: rgba(14, 18, 20, .6);
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out, z-index 0s .3s, top 0s .3s;
    cursor: default;

    html.show_popup & {
      opacity: 1;
      pointer-events: auto;
    }

    html.popup_menu &,
    html.popup_profile &,
    html.popup_stories &,
    html.popup_photos & {
      opacity: 0;
      pointer-events: none;
    }
	}

  &-menu {
    background: #fefbf9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1996;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: fixed;
    overflow: hidden;
    padding-top: $header_height + 35px;
    display: flex;

    html.popup_menu & {
      opacity: 1;
      pointer-events: auto;
    }

    &__wrap {
      display: flex;
      align-items: flex-start;
      overflow: auto;
      position: relative;
      width: 100%;
      padding-bottom: 8vh;
    }

    &__tag {
      position: absolute;
      bottom: -90px;
      left: -50px;
      right: -50px;
      pointer-events: none;
      z-index: -1;

      img {
        display: block;
        width: 100%;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__body {
      width: 410px;
      flex-shrink: 0;
    }

    &__title {
      font-size: 34px;
      line-height: 50px;
      color: #5f5e62;
      font-family: $font_core_rhino;
      margin-bottom: 45px;
    }

    &__text {
      font-size: 18px;
      line-height: 33px;
      color: #5c626b;
      font-family: $font_core_rhino;
      margin-bottom: 60px;
    }

    &__social {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      a {
        display: block;
        font-size: 22px;
        text-decoration: underline;
        color: #3c3f43;
        font-family: $font_core_rhino;
        font-weight: 700;
        transition: all .2s;

        &:hover {
          color: #9c1614;
          text-decoration: underline;
        }
      }
    }

    &-list {
      margin-right: 60px;

      &__item {
        font-size: 64px;
        font-family: $font_kent_4f_printed;
        color: #36393e;
        line-height: 1;

        & + .popup-menu-list__item {
          margin-top: 40px;
        }

        a {
          color: #36393e;
          text-decoration: none;

          &:hover {
            color: #9c1614;
            text-decoration: none;
          }
        }
      }
    }

    .content-layout {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &-profile {
    background: #fefbf9;
    padding-top: $header_height;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1996;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    html.popup_profile & {
      opacity: 1;
      pointer-events: auto;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $header_height;
      background: #fff;
      pointer-events: none;
      box-shadow: 0 30px 20px -30px rgba(0, 0, 0, .1);
    }

    &__wrap {
      display: flex;
      align-items: flex-start;
      overflow: auto;
      position: relative;
      width: 100%;
      padding-bottom: 10vh;
      height: 100%;
    }

    &__close {
      position: relative;
      float: right;
      width: 87px;
      height: 87px;
      border-radius: 50%;
      cursor: pointer;
      margin-top: 25px;
      z-index: 20;

      div {
        position: absolute;
        width: 28px;
        height: 3px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        pointer-events: none;
        transition: all .3s;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #9c1614;
          border-radius: 10px;
          transition: all .3s;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    &__title {
      margin-bottom: 80px;
      text-align: center;
    }

    &__form {
      max-width: 1160px;
      margin: 0 auto;

      .input__wrap {
        margin-bottom: 30px;
        width: auto;
      }

      .input-select {
        width: auto;

        &__list {
          max-height: 173px;
        }
      }
    }

    .content-layout {
      margin-top: auto;
      margin-bottom: auto;
    }

    .form-completed {
      margin-top: auto;
      margin-bottom: auto;
      background: none;
      padding: 0 !important;
      width: 100%;
    }
  }

  &-city-confirm {
    text-align: center;

    html.popup_city-confirm & {
      opacity: 1;
      pointer-events: auto;
    }

    .button_no-border {
      &:hover {
        color: #36393e;
      }
    }
  }

  &-city-select {
    html.popup_city-select &.popup-city-select_current {
      opacity: 1;
      pointer-events: auto;
    }

    html.popup_city-select-local &.popup-city-select_local {
      opacity: 1;
      pointer-events: auto;
    }

    &__city-item {
      font-size: 22px;
      color: #9c1614;
      font-family: $font_core_rhino;
      font-weight: 700;
      margin-bottom: 10px;

      a {
        color: #9c1614;
        text-decoration: none;

        &:hover {
          color: #393c41;
          text-decoration: none;
        }
      }
    }
  }

  &-stories {
    position: absolute;
    transform: none;
    transition: opacity .3s ease-in-out;
    top: 0;
    left: 0;
    height: 100%;
    background: none;
    max-width: none;
    padding: 0;

    html.popup_stories & {
      opacity: 1;
      pointer-events: auto;
    }

    &__close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__item {
      max-width: 1024px;
      padding: 90px 0 20px 0;
      background: #fff;
      position: relative;
      z-index: 2;
    }

    &__row {
      display: flex;
      align-items: flex-start;
    }

    &__photo {
      flex-shrink: 0;
      width: 360px;
      margin: 0 60px 0 0;
      display: block;
    }

    &__body {
      width: 100%;
      padding-top: 20px;
    }

    &__title {
      font-size: 34px;
      color: #36393e;
      font-family: $font_kent_4f_printed;
      margin-bottom: 5px;
      line-height: 1;
    }

    &__post {
      font-size: 18px;
      color: #88909b;
      font-family: $font_core_rhino;
      margin-bottom: 20px;
    }

    &__text {
      font-size: 18px;
      line-height: 30px;
      color: #36393e;
      font-family: $font_core_rhino;

      p {
        margin: 0;

        & + p {
          margin-top: 15px;
        }
      }
    }

    .popup__wrap {
      padding: 0 90px 70px 90px;
      max-height: 90vh;
    }

    .glide {
      height: 100%;

      &__track,
      &__slides {
        height: 100%;
      }

      &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      &__arrow {
        top: 50%;
        margin-top: -45px;
        position: absolute;
        z-index: 10;

        &--left {
          left: 60px;
        }

        &--right {
          right: 60px;
        }
      }
    }
  }

  &-photos {
    background: transparent;
    width: 100%;
    height: 100%;
    max-width: none;
    top: 0;
    left: 0;
    transform: none;
    transition: opacity .3s ease-in-out;
    padding: 0;

    html.popup_photos & {
      opacity: 1;
      pointer-events: auto;
    }

    &__close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__img {
      display: block;
      max-width: 70vw;
      max-height: 90vh;

      &-wrap {
        position: relative;
        z-index: 2;
      }
    }

    .popup__close {
      background-color: rgba(36, 40, 48, .7);

      &:before,
      &:after {
        background: #fff;
      }
    }

    .glide {
      height: 100%;
      background: rgba(14, 18, 20, .6);

      &__track,
      &__slides {
        height: 100%;
      }

      &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      &__arrow {
        top: 50%;
        margin-top: -45px;
        position: absolute;
        z-index: 10;

        &--left {
          left: 60px;
        }

        &--right {
          right: 60px;
        }
      }
    }
  }
}

@media (max-height: 740px) {
  .popup {
    &-menu {
      &__title {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 40px;
      }

      &__text {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 40px;
      }

      &-list {
        &__item {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-height: 660px) {
  .popup {
    &-menu {
      &-list {
        &__item {
          font-size: 40px;

          & + .popup-menu-list__item {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1360px) {
  .popup {
    &-menu {
      &-list {
        &__item {
          font-size: 50px;
        }
      }
    }

    &-stories {
      .glide {
        &__arrow {
          &--left {
            left: 3%;
          }

          &--right {
            right: 3%;
          }
        }
      }
    }

    &-photos {
      .glide {
        &__arrow {
          &--left {
            left: 3%;
          }

          &--right {
            right: 3%;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .popup {
    &-menu {
      &__title {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 40px;
      }

      &__text {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 40px;
      }

      &-list {
        &__item {
          font-size: 40px;

          & + .popup-menu-list__item {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .popup {
    &-stories {
      &__item {
        max-width: calc(100vw - 100px);
      }
    }
  }
}

@media (max-width: 991.98px) {
  .popup {
    &__title {
      font-size: 30px;
    }

    &-menu {
      padding-top: $header_height_mobile;

      &__body {
        width: 100%;
        flex-shrink: 1;
        max-width: 400px;
      }

      &__title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
      }

      &__text {
        line-height: 22px;
        margin-bottom: 30px;
      }

      &__social {
        a {
          font-size: 18px;
        }
      }

      &-list {
        &__item {
          font-size: 30px;
        }
      }
    }

    &-profile {
      padding-top: $header_height_mobile;

      &:before {
        height: $header_height_mobile;
      }

      &__close {
        width: 50px;
        height: 50px;
        margin-top: 10px;

        div {
          width: 20px;
        }
      }

      &__title {
        margin-bottom: 40px;
      }

      &__form {
        .input-select {
          &__list {
            max-height: 170px;
          }
        }
      }
    }

    &-stories {
      position: fixed;

      &__item {
        padding-top: 50px;
        max-width: 630px;
      }

      &__row {
        display: block;
      }

      &__photo {
        width: 100%;
        max-width: 320px;
        margin: 0;
      }

      &__body {
        width: auto;
      }

      &__title {
        font-size: 24px;
      }

      &__post {
        font-size: 16px;
        line-height: 24px;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
      }

      .popup__close {
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;

        &:before,
        &:after {
          width: 15px;
          top: 14px;
          left: 8px;
        }
      }

      .popup__wrap {
        padding: 0 20px 20px 20px;
        max-height: 80vh;
      }

      .glide {
        &__arrow {
          margin-top: -15px;
          top: 50%;

          &--left {
            left: 10px;
          }

          &--right {
            right: 10px;
          }
        }
      }
    }

    &-photos {
      &__img {
        max-width: 95vw;
        max-height: 90vh;
      }

      .popup__close {
        top: 20px;
        right: 20px;
      }

      .glide {
        &__arrow {
          margin-top: -15px;
          top: 50%;

          &--left {
            left: 10px;
          }

          &--right {
            right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .popup {
    &-profile {
      &__form {
        .col-12.text-right {
          text-align: center !important;
          padding: 30px 0 15vh 0;
        }
      }
    }
  }
}

@media (max-width: 665.98px) {
  .popup {
    transform: translate(-50%, 30px);
    top: 0;
    max-width: 300px;
    padding-top: 50px;

    &__wrap {
      padding: 0 20px 20px 20px;
    }

    &__close {
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;

      &:before,
      &:after {
        width: 15px;
        top: 14px;
        left: 8px;
      }
    }

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    &-menu {
      padding-top: 100px;

      &__wrap {
        display: block;
        width: auto;
      }

      &__tag {
        bottom: -20px;
        left: -10px;
        right: -10px;
      }

      &__row {
        display: block;
      }

      &__body {
        width: auto;
      }

      &__title {
        display: none;
      }

      &__text {
        display: none;
      }

      &__social {
        max-width: 320px;

        a {
          font-size: 16px;
        }
      }

      &-list {
        margin: 0 0 10vh 0;

        &__item {
          font-size: 26px;
        }
      }

      .content-layout {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-profile {
      &__wrap {
        display: block;
        width: auto;
        padding: 0;
      }

      &__close {
        width: 40px;
        height: 40px;
      }

      &__title {
        font-size: 30px;
        margin-bottom: 30px;
      }

      .content-layout {
        margin-top: 0;
        margin-bottom: 0;
      }

      .form-completed {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 40px !important;
        width: auto;
      }
    }

    &-city-confirm {
      .button_bordered {
        margin-bottom: 10px;
      }
    }

    &-stories {
      transform: none;
      top: 0;
      left: 0;
      max-width: none;
      padding: 0;

      &__item {
        max-width: 300px;
      }
    }

    &-photos {
      transform: none;
      top: 0;
      left: 0;
      max-width: none;
      padding: 0;

      .popup__close {
        display: none;
      }
    }
  }
}
